//  libraries
import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

const Careers = () => {
  return (
    <Layout>
      <SEO title="Careers Resources" />
      <iframe
        id="if1"
        title="careers page"
        width="100%"
        style={{ height: '100vh', border: 'none' }}
        src="https://recruiting.paylocity.com/recruiting/jobs/All/5ec94fbf-91c1-4c0c-bcb5-0341c1186e1c/Edge-Logistics"
      ></iframe>
    </Layout>
  )
}

export default Careers
